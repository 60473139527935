const ShoppingIcon = ()=> <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"  className="w-16 h-16">
  <path
    d="M253.3 35.1c6.1-11.8 1.5-26.3-10.2-32.4s-26.3-1.5-32.4 10.2L117.6 192H32c-17.7 0-32 14.3-32 32s14.3 32 32 32L83.9 463.5C91 492 116.6 512 146 512H430c29.4 0 55-20 62.1-48.5L544 256c17.7 0 32-14.3 32-32s-14.3-32-32-32H458.4L365.3 12.9C359.2 1.2 344.7-3.4 332.9 2.7s-16.3 20.6-10.2 32.4L404.3 192H171.7L253.3 35.1zM192 304v96c0 8.8-7.2 16-16 16s-16-7.2-16-16V304c0-8.8 7.2-16 16-16s16 7.2 16 16zm96-16c8.8 0 16 7.2 16 16v96c0 8.8-7.2 16-16 16s-16-7.2-16-16V304c0-8.8 7.2-16 16-16zm128 16v96c0 8.8-7.2 16-16 16s-16-7.2-16-16V304c0-8.8 7.2-16 16-16s16 7.2 16 16z"

  />
</svg>
const ImageIcon = ()=> <svg xmlns="http://www.w3.org/2000/svg" height="1em" className="w-16 h-16" viewBox="0 0 512 512">
  {/*! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. */}
  <path d="M448 80c8.8 0 16 7.2 16 16V415.8l-5-6.5-136-176c-4.5-5.9-11.6-9.3-19-9.3s-14.4 3.4-19 9.3L202 340.7l-30.5-42.7C167 291.7 159.8 288 152 288s-15 3.7-19.5 10.1l-80 112L48 416.3l0-.3V96c0-8.8 7.2-16 16-16H448zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm80 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z" />
</svg>
const LikeIcon = ()=> <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"  className="w-16 h-16">
  {/*! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. */}
  <path d="M313.4 32.9c26 5.2 42.9 30.5 37.7 56.5l-2.3 11.4c-5.3 26.7-15.1 52.1-28.8 75.2H464c26.5 0 48 21.5 48 48c0 18.5-10.5 34.6-25.9 42.6C497 275.4 504 288.9 504 304c0 23.4-16.8 42.9-38.9 47.1c4.4 7.3 6.9 15.8 6.9 24.9c0 21.3-13.9 39.4-33.1 45.6c.7 3.3 1.1 6.8 1.1 10.4c0 26.5-21.5 48-48 48H294.5c-19 0-37.5-5.6-53.3-16.1l-38.5-25.7C176 420.4 160 390.4 160 358.3V320 272 247.1c0-29.2 13.3-56.7 36-75l7.4-5.9c26.5-21.2 44.6-51 51.2-84.2l2.3-11.4c5.2-26 30.5-42.9 56.5-37.7zM32 192H96c17.7 0 32 14.3 32 32V448c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V224c0-17.7 14.3-32 32-32z" />
</svg>
const FileIcon = ()=><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"  className="w-16 h-16">
  {/*! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. */}
  <path d="M64 464c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16H224v80c0 17.7 14.3 32 32 32h80V448c0 8.8-7.2 16-16 16H64zM64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V154.5c0-17-6.7-33.3-18.7-45.3L274.7 18.7C262.7 6.7 246.5 0 229.5 0H64zm96 256a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm69.2 46.9c-3-4.3-7.9-6.9-13.2-6.9s-10.2 2.6-13.2 6.9l-41.3 59.7-11.9-19.1c-2.9-4.7-8.1-7.5-13.6-7.5s-10.6 2.8-13.6 7.5l-40 64c-3.1 4.9-3.2 11.1-.4 16.2s8.2 8.2 14 8.2h48 32 40 72c6 0 11.4-3.3 14.2-8.6s2.4-11.6-1-16.5l-72-104z" />
</svg>
const PrinterIcon = ()=> <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" className="w-16 h-16">
  {/*! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. */}
  <path d="M128 0C92.7 0 64 28.7 64 64v96h64V64H354.7L384 93.3V160h64V93.3c0-17-6.7-33.3-18.7-45.3L400 18.7C388 6.7 371.7 0 354.7 0H128zM384 352v32 64H128V384 368 352H384zm64 32h32c17.7 0 32-14.3 32-32V256c0-35.3-28.7-64-64-64H64c-35.3 0-64 28.7-64 64v96c0 17.7 14.3 32 32 32H64v64c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V384zM432 248a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
</svg>
const LetterIcon = () => <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" className="w-16 h-16">
  {/*! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. */}
  <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" />
</svg>
export const monthlyDeal = [
  {
    title: "FlowerArchitect Basic Membership",
    price: "9.99",
    id: 1,
    type: "basic",
    paymentType: "recurring",
    priceId: "flw_basic_monthly",
    flowerArchitectId: "basicM",
    stripeId: "prod_Ikj1ZhNaTCiRsl",
    description: [
      "- Flower Library - full access to flower image database over 1800 flowers and thousands of embellishments (vases, containers, birds, butterflies, jewels, feathers, fruit, etc)",
      "- Samples - basic arrangement & basket samples library (Vase, wristlet, bridal and few more types)",
      "- 10 Storage Tokens -  each storage token allows for storage of one basket, arrangement, or scene as long as you membership is current",
      "- 1 Enlargement Token - sends to Zenfolio photographers website and enlarge to 300dpi and download (Also store to buy prints, oils, t-shirts, etc) (one time use)",
      "- Share -  share Your arrangement with your friends or send to Pinterest",
    ],
    buttonText: "BUY",
    buttonVariant: "contained",
  },
  {
    title: "Enhanced",
    subheader: "Most popular",
    id: 2,
    type: "club",
    paymentType: "recurring",
    price: "14.99",
    flowerArchitectId: "clubM",
    stripeId: "prod_BTQElBYI4sMMjv",
    priceId: "flw_designer_monthly",
    description: [
      "- Flower Library - full access to flower image database over 1800 flowers and thousands of embellishments (vases, containers, birds, butterflies, jewels, feathers, fruit, etc)",
      "- Samples - basic arrangement & basket samples library (Vase, wristlet, bridal and few more types)",
      "- 4 Storage Tokens for high Resolution Venue Background Images for your scenes",
      "- 50 Storage Tokens. each storage token allows for storage of one basket,  arrangement or scene  as long as you membership is current",
      "- Scenes - ability to Create Scenes with your background image and your arrangements placed in scene. (You in your wedding dress with flowers) upload 1  background image (venue )  as long as you membership is current",
      "- 2 Enlargement Tokens - sends to Zenfolio photographers website and enlarge to 300dpi and download (Also store to buy prints, oils, t-shirts, etc) (one time use)",
      "- Shape Aid Templates - 22 Templates To layout on the canvas to assist in the layout of your flower arrangements",
      "- Share -  share Your arrangement with your friends or send to Pinterest",
    ],
    buttonText: "BUY",
    buttonVariant: "contained",
  },
  {
    title: "Designer (Recommended for Brides)",
    id: 3,
    type: "designer",
    paymentType: "recurring",
    price: "19.99",
    flowerArchitectId: "designerM",
    stripeId: "prod_IneJf4dTkTwvgr",
    priceId: "price_1IC31HLp679ATwPBonbiGMPi",
    description: [
      "- Flower Library - full access to flower image database over 1800 flowers and thousands of embellishments (vases, containers, birds, butterflies, jewels, feathers, fruit, etc)",
      "- Samples - full samples library of arrangements and baskets grouped by (vase arrangement, bridal bouquet, wristlet, tropical, wildflower, etc.)",
      "- 200 Storage Tokens. each storage token allows for storage of one basket,  arrangement or scene  as long as you membership is current",
      "- Scenes - ability to Create Scenes with your background image and your arrangements placed in scene. upload and store 10  background images (venues )  as long as you membership is current",
      "- 10 Enlargement Tokens - sends to Zenfolio photographers website and enlarge to 300dpi and download (Also store to buy prints, oils, t-shirts, etc)",
      "- 5 Quotations - Ability to send emails with quotation request pdf show your arrangement, associated flower list and your instructions (card, vase, size, etc) (one time use)",
      "- Color Wheel  - search for flower that match color wheel",
      "- Groups - Ability to create groups and invite friends and share you arrangements, baskets, and scenes",
      "- Collages - Access to pre-defined color coordinated flower Collages with associated baskets",
      "- Flower Data Library - Data library for each flower category (200+) (seasonal availability, relative cost, vase life, shape, texture, etc.)",
      "- Menu Driven search of flower database by example from menu drop-downs",
      "- Share -  share Your arrangement with your friends or send to Pinterest",
    ],
    buttonText: "BUY",
    buttonVariant: "contained",
  }
];
export const arrangementDeals = [
  {
    title: "Storage Tokens",
    price: "9.99",
    id: 5,
    paymentType: "oneTime",
    flowerArchitectId: "storage25",
    StripeId: "prod_Io4QctJ3AOjz0v",
    priceId: "price_1ICSHZLp679ATwPBZWnla2U3",
    description: [
      "- extra Tokens for more storage to add to your membership (each token can be used to store a basket, arrangement, or scene 25 for $9.99 dollars ",
    ],
    buttonText: "BUY",
    buttonVariant: "contained",
  },
  {
    title: "Enlargement Tokens",
    subheader: "Most popular",
    price: "4.99",
    id: 6,
    paymentType: "oneTime",
    flowerArchitectId: "enlargement10",
    StripeId: "prod_Io4VpoDXBiq0Po",
    priceId: "price_1ICSM5Lp679ATwPB7qlVxvOi",
    description: [
      "- 10 for $4.99 dollars sends to Zenfolio photographers website and enlarge to 300dpi and download (Also store to buy prints, oils, t-shirts, etc) (one time use)",
    ],
    buttonText: "BUY",
    buttonVariant: "contained",
  },
  {
    title: "Venue Tokens",
    id: 7,
    paymentType: "oneTime",
    flowerArchitectId: "venue10",
    StripeId: "prod_Io4bdCRbmTxRX3",
    priceId: "price_1ICSSMLp679ATwPBJXoLqgiq",
    price: "4.99",
    description: [
      " - store extra venue images with your membership 10 for a 4.99 dollars ",
    ],
    buttonText: "BUY",
    buttonVariant: "contained",
  },
  {
    title: "Quotation Tokens",
    flowerArchitectId: "email50",
    StripeId: "prod_Io4eDe51MHFcCd",
    priceId: "price_1ICSUlLp679ATwPBMy1HLcUp",
    price: "4.99",
    id: 8,
    paymentType: "oneTime",
    description: ["- send extra Quotation emails for 50 for 4.99 dollars"],
    buttonText: "BUY",
    buttonVariant: "contained",
  },
];

export const subscriptionsList = [
 {
    title: "FlowerArchitect Basic Membership",
    tag:'Get Started',
    price: "9.99",
    icon: <div className="flex justify-center items-center "><div className="p-4 rounded-full bg-white my-3"><ShoppingIcon /></div></div>,
    id: 1,
    type: "Month",
    paymentType: "recurring",
    priceId: "flw_basic_monthly",
    flowerArchitectId: "basicM",
    stripeId: "prod_Ikj1ZhNaTCiRsl",
    link:'https://buy.stripe.com/8wM6ry3XS5yO5RmfZ8',
    className:"blue-card",
    description: [
      "Create Beautiful Flower Arrangements",
      "Flower Library Database over 1800 Flowers",
      "Basic Samples of Arrangements & baskets",
      "10 Storage Tokens (Arrangements & Baskets)",
      "1 Enlargement Token Monthly",
      "Share with your friends",
      "See Details Below.."
    ],
    buttonText: "Buy Basic Monthly Now!",
    buttonVariant: "contained",
  },

   {
    title: "FlowerArchitect Enhanced Membership",
    tag:'Do More',
      className:"green-card",
      icon: <div className="flex justify-center items-center "><div className="p-4 rounded-full bg-white my-3"><ImageIcon /></div></div>,
    price: "14.99",
    id: 1,
    type: "Month",
    paymentType: "recurring",
    priceId: "flw_basic_monthly",
    flowerArchitectId: "basicM",
    stripeId: "prod_Ikj1ZhNaTCiRsl",
    link:'https://buy.stripe.com/28obLS6601iyenSbIM',
    description: [
      "Full Samples of Arrangements & baskets",
      "50 Storage Tokens",
      "2 Enlargement Tokens Monthly",
      "Flower Collages Color Coordinated",
      "4 Scene Background Storage Tokens",
      "Create Virtual Scenes (Images, Garlands)",
      "See Details Below.."
    ],
    buttonText: "Buy Enhanced Monthly Now!",
    buttonVariant: "contained",
  },
   {
    title: "FlowerArchitect Designer Membership",
    tag:'Get Everything',
    className:"yellow-card",
    icon: <div className="flex justify-center items-center "><div className="p-4 rounded-full bg-white my-3"><LikeIcon /></div></div>,
    price: "19.99",
    id: 1,
    type: "Month",
    paymentType: "recurring",
    priceId: "flw_basic_monthly",
    flowerArchitectId: "basicM",
    stripeId: "prod_Ikj1ZhNaTCiRsl",
    link:'https://buy.stripe.com/7sIaHOcuo7GW3JedQV',
    description: [
      "200 Storage Tokens including Scenes & Images",
      "10 Scene Background Storage Tokens",
      "Flower Data Library for each flower category (200+)",
      "4 Enlargement Tokens Monthly",
      "Groups - invite friends to share & create together",
      "5 Email Quotations per Month",
      "Color Wheel To Search for Flowers",
      "See Details Below.."
    ],
    buttonText: "Buy Designer Monthly Now!",
    buttonVariant: "contained",
  },
  ,
   {
    title: "Basic Yearly.... Membership",
    tag:'Save - 1 Month Free!',
    className:"blue-card",
    icon: <div className="flex justify-center items-center "><div className="p-4 rounded-full bg-white my-3"><ShoppingIcon /></div></div>,
    price: "109.99",
    id: 1,
    type: "Yearly",
    paymentType: "recurring",
    priceId: "flw_basic_monthly",
    flowerArchitectId: "basicM",
    stripeId: "prod_Ikj1ZhNaTCiRsl",
    link:'https://buy.stripe.com/fZe5nu8e85yO7Zu7st',
    description: [
     "Same As Basic Monthly Above"
    ],
    buttonText: "Buy Basic Yearly Membership Now!",
    buttonVariant: "contained",
  },
  ,
   {
    title: "Enhanced Yearly Membership",
    tag:'Save - 1 Month Free!',
      className:"green-card",
    icon: <div className="flex justify-center items-center "><div className="p-4 rounded-full bg-white my-3"><ImageIcon /></div></div>,
    price: "164.99",
    id: 1,
    type: "Yearly",
    paymentType: "recurring",
    priceId: "flw_basic_monthly",
    flowerArchitectId: "basicM",
    stripeId: "prod_Ikj1ZhNaTCiRsl",
    link:'https://buy.stripe.com/3cs8zGfGA8L01B6eUX',
    description: [
     "Same As Enhanced Monthly Above  "
    ],
    buttonText: "Buy Enhanced Yearly Membership Now!",
    buttonVariant: "contained",
  },
   ,
   {
    title: "Designer Yearly Membership",
    tag:'Save - 1 Month Free!',
      className:"yellow-card",
    icon: <div className="flex justify-center items-center "><div className="p-4 rounded-full bg-white my-3"><LikeIcon /></div></div>,
    price: "219.99",
    id: 1,
    type: "Yearly",
    paymentType: "recurring",
    priceId: "flw_basic_monthly",
    flowerArchitectId: "basicM",
    stripeId: "prod_Ikj1ZhNaTCiRsl",
    link:'https://buy.stripe.com/00g3fmgKEgdsgw028a',
    description: [
     "Same As Designer Monthly Above"
    ],
    buttonText: "Buy Designer Yearly Membership Now!",
    buttonVariant: "contained",
  },
  ///

 ,
   {
    title: "FlowerArchitect Additional Storage Tokens",
    tag:'Store 25 More Arrangements',
      className:"blue-card",
      icon: <div className="flex justify-center items-center "><div className="p-4 rounded-full bg-white my-3"><FileIcon /></div></div>,
    price: "9.99",
    id: 1,
    type: "Each",
    paymentType: "recurring",
    priceId: "flw_basic_monthly",
    flowerArchitectId: "basicM",
    stripeId: "prod_Ikj1ZhNaTCiRsl",
    link:'https://buy.stripe.com/6oE17ebqk9P45Rm4gr',
    description: [
     "25 Additional Storage Tokens on top of your membership storage token allotment.",
     "Each Token Stores a basket, arrangement, image, garland or scene for as long as your membership is active.",
     "See Details Below.."
    ],
    buttonText: "Buy 25 Storage Tokens Now!",
    buttonVariant: "contained",
  },
   ,
   {
    title: "FlowerArchitect Enlargement Tokens",
    tag:'10 More Zenfolio Print Quality',
      className:"green-card",
      icon: <div className="flex justify-center items-center "><div className="p-4 rounded-full bg-white my-3"><PrinterIcon /></div></div>,
    price: "4.99",
    id: 1,
    type: "Each",
    paymentType: "recurring",
    priceId: "flw_basic_monthly",
    flowerArchitectId: "basicM",
    stripeId: "prod_Ikj1ZhNaTCiRsl",
    link:'https://buy.stripe.com/3cs03a3XS9P42FafZ5',
    description: [
     "10 one time use Enlargement Tokens on top of your membership storage token allotment.",
     "Send Your Arrangements or Scenes to Zenfolio photographers website and enlarge to 300dpi",
     "Then You Can Download, Buy Prints, Oils or Print On t-shirts, cups etc.",
     "See Details Below.."
    ],
    buttonText: "Buy 10 Enlargements Now!",
    buttonVariant: "contained",
  },
   {
    title: "FlowerArchitect Scene Background (Venue) Tokens",
    tag:'Store 10 Scene Backgrounds',
      className:"yellow-card",
      icon: <div className="flex justify-center items-center "><div className="p-4 rounded-full bg-white my-3"><ImageIcon /></div></div>,
    price: "4.99",
    id: 1,
    type: "Each",
    paymentType: "recurring",
    priceId: "flw_basic_monthly",
    flowerArchitectId: "basicM",
    stripeId: "prod_Ikj1ZhNaTCiRsl",
    link:'https://buy.stripe.com/eVa03acuo1iy7ZufZ6',
    description: [
     "10 Additional Venue Storage Tokens on top of your membership storage token allotment.",
     "Each Token Stores a Venue (Scene Background Image) for as long as your membership is active.",

     "See Details Below.."
    ],
    buttonText: "Buy 10 Venue Tokens Now!",
    buttonVariant: "contained",
  },
   {
    title: "Flower Architect Email Quotation Tokens",
    tag:'50 Quotations Tokens',
      className:"red-card",
      icon: <div className="flex justify-center items-center "><div className="p-4 rounded-full bg-white my-3"><LetterIcon /></div></div>,
    price: "4.99",
    id: 1,
    type: "Each",
    paymentType: "recurring",
    priceId: "flw_basic_monthly",
    flowerArchitectId: "basicM",
    stripeId: "prod_Ikj1ZhNaTCiRsl",
    link:'https://buy.stripe.com/4gw2bidys0eubbG7sB',
    description: [
      "50 one time use Email Quotation Tokens on top of your membership storage token allotment.",
      "Send Request For Quotations with PDF including an Image of Your Arrangement, list of flower types, card details, etc",
      "Also Includes Delivery Address and Special Details in Quote Request",
      "See Details Below.."
    ],
    buttonText: "Buy 50 Quotation Tokens Now!",
    buttonVariant: "contained",
  }
]
